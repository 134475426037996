#hero {
	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 40px;

	position: absolute;
	width: 478px;
	height: 437px;
	left: 91px;
	top: 350px;
}

#hero h1 {
	width: 478px;
	height: 180px;

	font-weight: 700;
	font-size: 48px;
	line-height: 60px;

	/* or 125% */
	letter-spacing: -0.025em;
	font-feature-settings: "liga" off;

	color: var(--alt-color);

	/* Inside auto layout */
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
	margin: 0;
}

#hero p {
	width: 478px;
	height: 135px;

	font-size: 36px;
	line-height: 45px;

	/* or 125% */
	letter-spacing: -0.025em;
	font-feature-settings: "liga" off;

	color: #000000;

	margin: 0;
}

#hero-buttons {
	/* Auto layout */
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 24px;

	width: 413px;
	height: 66px;

	/* Inside auto layout */
	flex: none;
	order: 1;
	flex-grow: 0;
}

#hero-buttons .get-started {
	padding: 24px 36px;

	height: 66px;

	background: var(--alt-color);
	border-radius: 40px;

	font-size: 18px;
	font-weight: 700;
	line-height: 18px;
	color: #fdb813;
	/* identical to box height, or 100% */
	text-align: center;
}

#hero-buttons .get-started:hover img {
	filter: brightness(1000000%);
}

#hero-buttons .get-started img {
	margin-left: 8px;
}

.talk-to-sales {
	padding: 24px 36px;

	height: 66px;

	background: #fdb813;
	border-radius: 36.5499px;

	font-weight: 700;
	font-size: 18px;
	line-height: 18px;

	/* identical to box height, or 100% */
	text-align: center;

	color: #33336f;

	border: none;
}

#hero-image {
	position: absolute;
	right: 100px;
	top: 303px;
}

#clouds {
	position: absolute;
	left: 0;
	right: 0;
	top: 64px;
	height: 235px;
	overflow: hidden;
	z-index: -1;
	background-repeat: repeat-x;
	/* animation: clouds 10s ease-in-out infinite; */
	animation: clouds 10s linear infinite;
}

@keyframes clouds {
	0% {
		background-position: 0 0;
	}
	
	100% {
		background-position: -1260px 0;
	}
}

@media screen and (min-width: 1500px) {
	#hero { left: 10vw; }
	#hero-image { right: 10vw; }
}

@media screen and (min-width: 2000px) {
	#hero { left: 20vw; }
	#hero-image { right: 20vw; }
}

@media screen and (min-width: 3200px) {
	#hero { left: 30vw; }
	#hero-image { right: 30vw; }
}

@media screen and (max-width: 1300px) {
	#hero {
		left: 30px;
	}

	#hero-image {
		right: 10px;
	}
}

@media screen and (max-width: 1100px) {
	#hero-image img {
		width: 50vw;
	}

	#hero-image {
		top: 375px;
	}
}

@media screen and (max-width: 1000px) {
	#hero-image {
		position: unset;
		display: inline-block;
		margin-top: 63px;
	}

	#hero {
		position: unset;
		margin: auto 0;
	}

	#index {
		display: flex;
		align-items: center;
		flex-direction: column;
	}	

	#index-margin {
		display: none;
	}
}

@media screen and (max-width: 800px) {
	#hero {
		height: auto;
		margin-bottom: 32px;
		max-width: 90vw;
		gap: 24px;
	}

	#hero h1 {
		font-size: 32px;
		letter-spacing: -0.8px;
		line-height: 120%;
		height: auto;
		width: 100%;
	}

	#hero p {
		width: 100%;
		font-size: 26px;
	}

	#hero-buttons {
		flex-direction: column;
		height: auto;
		width: 100%;
		gap: 12px;
	}

	#hero-buttons > *,
	#hero-buttons > * > * {
		display: block;
		width: 100%;
	}

	#hero-image img {
		width: 75vw;
	}
}

#index-margin {
	margin-top: 815px;
}

#trusted-by-companies {
	background: #4d4d83;
	font-size: 36px;
	text-align: center;
	color: white;
	font-weight: 700;
	z-index: 2;
	height: 50px;
	padding: 24px 0 40px 0;
}

#companies-using {
	margin: auto;
	margin-top: -24px;
	background: var(--alt-color);
	padding: 40px;
	border-radius: 24px;
	max-width: 1160px;

	display: flex;
	justify-content: space-around;
}

#about-us {
	background: var(--main-color);
	padding: 80px 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	/* gap: 140px; */
}

#about-us-info {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 445px;
	max-width: 95vw;
}

#about-us h1 {
	color: var(--alt-color);
	font-size: 44px;
	line-height: 50px;
	margin: 0;
	margin-top: -8px;
}

#about-us p {
	max-width: 455px;
	color: #242424;
	font-size: 20px;
	line-height: 150%;
	margin: 0;
}

#about-us-info > div {
	display: flex;
	gap: 16px;
	align-items: center;
	color: #242424;
	font-size: 18px;
	line-height: 150%;
}

#about-us-image img {
	max-width: 50vw;
}

@media screen and (min-width: 2200px) {
	#about-us {
		justify-content: center;
		gap: 320px;
	}
}

@media screen and (max-width: 1200px) {
	#about-us {
		justify-content: center;
		gap: 24px;
		padding: 80px 10px;
	}
	
	#about-us-image img {
		max-width: 45vw;
	}
}

@media screen and (max-width: 850px) {
	#about-us {
		padding: 40px 16px;
		flex-direction: column;
		text-align: center;
	}

	#about-us-info > :first-child {
		margin: auto;
	}

	#about-us-image img {
		max-width: 90vw;
	}
}

#browse-our-products {
	margin-top: 80px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	margin-bottom: 80px;
}

#browse-our-products h1 {
	color: #1e285f;
	font-size: 48px;
	margin: 0;
}

#browse-our-products > p {
	font-size: 20px;
	margin: 0;
	max-width: 876px;
	width: 90vw;
}

#products {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 24px;
}

@media screen and (max-width: 1200px) {
	#products {
		grid-template-columns: 1fr;
	}

	#products > div {
		width: calc(100vw - 100px) !important;
		height: auto !important;
	}
}

#sec-2 {
	display: flex;
	padding: 80px 120px;
	justify-content: center;
	align-items: center;
	gap: 64px;
}

#sec-2 h2 {
	color: #ff5a5a;
	font-size: 48px;
	font-weight: 700;
	line-height: 60px;
}

#sec-2 p {
	color: #242424;
	font-size: 20px;
	line-height: 150%;
}

#sec-2 button {
	border-radius: 36.55px;
	border: 1px solid var(--red);
	background: #fff;
	padding: 24px 36px;
	color: var(--red);
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	line-height: 18px;
}

#macbook {
	margin-right: -120px;
}

#sec-3 {
	position: relative;
	width: 100%;
}

#sec-3-background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
}

#sec-3-background img {
	width: 100%;
	height: 100%;
}

#sec-3-images {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 80px;

	padding-top: 340px;
	padding-bottom: 240px;
}

#our-process {
	display: flex;
	flex-direction: column;
	gap: 64px;
	align-items: center;
	margin-bottom: 96px;
	margin-top: -20px;
}

#get-started-header {
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: center;
}

#get-started-header h1 {
	margin: 0;
}

#get-started-message {
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
}

#get-started-message p {
	margin: 0;
	color: #242424;
	font-size: 20px;
	line-height: 140%;
	max-width: 705px;
	text-align: center;
}

.steps {
	display: flex;
	gap: 52px;
}

#images {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	margin-top: 160px;
	margin-bottom: 80px;
}

#images-header {
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
}

#images-header h1 {
	color: #33336f;
	text-align: center;
	font-size: 48px;
	font-weight: 700;
	line-height: 60px;
	max-width: 912px;
	margin: 0;
}

#images-header p {
	color: #242424;
	text-align: center;
	font-size: 20px;
	font-weight: 400;
	line-height: 150%;
	margin: 0;
	max-width: 615px; 
}

#image-container {
	display: flex;
	gap: 24px;
}

.image-column {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

#images img {
	border-radius: 25px;
}

#account-creation-prompt {
	background: #1E285F;
	padding: 100px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	margin-bottom: 80px;
}

#account-creation-prompt h1 {
	color: var(--main-color);
	width: 570px;
	max-width: 90vw;
	text-align: center;
	margin: 0;
}

#account-creation-buttons {
	display: flex;
	gap: 24px;
}

#account-creation-prompt button {
	padding: 28px 56px;
	border-radius: 48px;
	text-align: center;
	font-size: 20px;
	line-height: 20px;
	font-family: var(--main-font);
}

#get-started-2 {
	font-weight: 700;
	border: none;
}

#talk-to-sales-2 {
	background: none;
	color: white;
	border: solid thin white;
}

@media screen and (max-width: 800px) {
	#account-creation-prompt {
		padding: 80px 0;
		margin-bottom: 0;
	}
}

#pricing {
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-bottom: 80px;
}

#pricing-header {
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;
}

#pricing-text {
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;
}

#pricing-text h1 {
	color: var(--alt-color);
	text-align: center;
	font-size: 56px;
	line-height: 66px;
	margin: 0;
}

#pricing-text p {
	color: #242424;
	text-align: center;
	font-size: 20px;
	line-height: 150%;
	max-width: 705px;
	margin: 0;
}

#monthly-button {
	padding: 18px 24px;
	border-radius: 30px;
	background: var(--alt-color);
	color: white;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	margin-right: 24px;
}

#yearly-button {
	padding: 18px 24px;
	border-radius: 30px;
	border: 1px solid var(--main-color);
	background: var(--main-color);
	color: #1D1C2B;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
}

#pricing-options {
	display: flex;
	gap: 18px;
	justify-content: center;
}

#faq {
	margin-top: 175px;
	/* display: flex; */
	display: none;
	flex-direction: column;
	align-items: center;
	gap: 26px;
}

#faq-header {
	display: flex;
	flex-direction: column;
	gap: 16px;
	font-family: DM Sans;
	align-items: center;
	text-align: center;
}

#faq-header h1 {
	color: #1E285F;
	margin: 0;
	
	font-size: 36px;
	font-weight: 700;
	line-height: 46px; 
	max-width: 90vw;
}

#faq-header p {
	margin: 0;
	max-width: 715px;
	font-size: 20px; 
	line-height: 150%;
}

@media screen and (max-width: 900px) {
	#faq {
		margin-top: 40px;
	}
}

#contact-us {
	margin: auto;
	margin-top: 200px;
	margin-bottom: 80px;
	display: flex;
	max-width: 1200px;
	align-items: start;
	justify-content: center;
	gap: 100px;
}

#contact-us-info {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

#contact-us-info h1 {
	margin: 0;
	margin-top: -12px;
	color: var(--alt-color);
	font-size: 44px;
	line-height: 50px;
}

#contact-us-info p {
	color: #242424;
	font-size: 18px;
	line-height: 30px;
	width: 505px;
	max-width: 90vw;
	margin: 0;
}

#contact-methods {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

#contact-methods div {
	display: flex;
	gap: 6px;
}

#contact-form {
	display: flex;
	flex-direction: column;
	gap: 12px;
	min-width: 505px;
}

#contact-inputs {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

#contact-inputs > * {
	border: none;
	background: #FBFBFB;
	color: #242424;
	padding: 12px 16px; 
	border-radius: 50px;
	width: calc(100% - 32px);
	font-size: 16px;
	font-family: var(--main-font);
}

#contact-inputs > textarea {
	border-radius: 16px; 
	resize: none;
	height: 105px;
}

#contact-form button {
	border-radius: 30px;
	padding: 18px 24px;
	width: 100%;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	border: none;
}

@media screen and (max-width: 1150px) {
	#contact-us {
		gap: 20px;
	}

	#contact-form {
		min-width: 405px;
	}
}

@media screen and (max-width: 1000px) {
	#contact-us {
		flex-direction: column;
		text-align: center;
		margin-top: 40px;
		align-items: center;
	}

	#contact-us-info {
		margin: 0 16px;
	}

	/* Horizontall center the <Chip /> */
	#contact-us-info > :first-child {
		margin: auto;
	}
}

@media screen and (max-width: 700px) { 
	#contact-form {
		width: 90vw;
		min-width: 0;
	}
}

.linear_gradient{
	background: linear-gradient(90deg, #FB8123 0%, #E34280 94.8%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}