.p-dropdown-items-wrapper,.p-multiselect-panel{
    color: #F1F5F9;
    background-color: #0F172A;
}
.p-inputtext.p-multiselect-filter{
    background-color: transparent;
    border: none;
    outline:none;
    color:#FFF
}
.p-checkbox-input{
    width:16px;
    height:16px;
    border:2px solid #64748B;
    color:#0F172A;
}
.p-checkbox-box{
    display: none;
}
.MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
    border: none;
}