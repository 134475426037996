#header-container {
	position: relative;
}

#header {
	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 12px 120px;
	gap: 16px;

	height: 64px;

	background: linear-gradient(90deg, #FB8123 0%, #E34280 94.8%);

	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	z-index: 5;
}

#ghost-header {
	display: block;
	height: 88px;
}

#header-logo {
	display: flex;
	align-items: center;
}

#header-sections {
	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 33px;

	height: 18px;
}

#header-sections > * {
	height: 18px;

	font-size: 18px;
	line-height: 18px;

	text-align: center;
	text-decoration: none;
	color: var(--alt-color);
}

#header-sections > div {
	height: 88px;
	display: flex;
	align-items: center;
}

#header-sections > * > div:first-child {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;

	height: 18px;
}

.header-dropdown {
	display: flex;
	gap: 15px;
	background: white;
	padding: 64px 120px;
	justify-content: center;

	position: absolute;
	opacity: 0;
	top: 0;
	right: 0;
	left: 0;
	/* height: 64px; */
	
	pointer-events: none;

	transition: 0.4s all;
}

#header-sections > *:hover > .header-dropdown,
#header-sections > *:active > .header-dropdown,
#header-sections > * > .header-dropdown:hover {
	top: 88px;
	opacity: 1;

	pointer-events: unset;
}
.orange-on-blue{
	color: #fdb813;
	background: var(--alt-color);
}

.header-dropdown .listing {
	display: flex;
	flex-direction: column;
	gap: 18px;
	width: 200px;
	text-decoration: none;
	justify-content: center;
	align-items: center;
}

.header-dropdown .placeholder-img {
	border-radius: 16px;
	background: #D9D9D9;
	height: 100px;
}

.header-dropdown .listing * {
	text-align: left;
	margin: 0;
	color: #E74E6E;
}

.header-dropdown .listing_vis * {
	text-align: left;
	margin: 0;
	color: #FD8819;
}

.header-dropdown .listing h4 {
	margin-bottom: 8px;
	color: #E74E6E;
}

.header-dropdown .learn-more {
	color: #4D4D83;
	margin-top: 6px;
	line-height: 16px;
	display: flex;
	gap: 8px;
}

#header-sections a a {
	color: black;
	text-decoration: none;
	text-align: left;
	margin-left: 8px;
}

#header-buttons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 24px;

	width: 212px;
	height: 40px;
}

.search-button {
	box-sizing: border-box;

	aspect-ratio: 1;
	width: 40px;

	background: #ffffff;
	border: 0.5px solid var(--alt-color);
	border-radius: 45px;

	position: relative;
}

.get-started {
	height: 40px;
	padding: 10px 24px;

	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	text-align: center;

	border: none;
	border-radius: 30px;
}
.get-logout{
	/* height: 40px; */
	box-sizing: border-box;

	aspect-ratio: 1;
	width: 40px;
	
	text-align: center;

	color: var(--main-color);
	background: var(--alt-color);

	border: none;
	border-radius: 45px;;
}

#header-mobile-button {
	display: none;

	background: #242424;
	width: 36px;
	height: 36px;
	border: none;
}

#header-mobile {
	width: 100%;
	background: var(--alt-color);
	color: white; 
	position: fixed;
	top: 72px;
	z-index: 5;
	display: none;
}

#mobile-search {
	background: white;
	width: calc(100% - 32px);
	padding: 0 16px;
	height: 48px;
	align-items: center;
	display: flex;
	font-size: 16px;
}

#mobile-search input {
	border: none;
	outline: none;
	flex-grow: 1;
	height: 24px;
	padding: 0;
	font-family: var(--main-font);
}

#mobile-search button {
	float: right;
	border: none;
	background: none;
}

.mobile-section {
	display: block;
	color: white;
	text-decoration: none;
	line-height: 48px;
	padding: 0 16px;
}
.login-highlight{
	background: var(--main-color);
	color: var(--alt-color);
}

.mobile-section.border-top {
	border-top: solid thin white;
}

@media screen and (max-width: 940px) {
	#header-sections {
		display: none;
	}

	#header-buttons {
		display: none;
	}

	#header {
		padding: 16px;
	}

	#ghost-header {
		height: 72px;
	}

	#header-mobile-button {
		display: block;
	}

	#header-mobile {
		display: unset;
	}

	#search {
		display: none !important;
	}
}

#search {
	position: fixed;
	top: 0;
	right: 10px;
	left: calc(100vw - 500px);
	bottom: calc(100vh - 52px - 10px);
	z-index: 4;
	
	display: grid;
	grid-template-columns: 1fr 100px;

	background: white;
	border-radius: 32px;
	border: solid thin black;
	
	transition: all 0.5s ease-out;
}

@media screen and (max-width: 1300px) {	
	#search.search-open {
		top: calc(88px + 16px);
		bottom: calc(100vh - 88px - 52px - 10px);
	}
}

#search input {
	background: none;
	border: none;
	padding-left: 16px;
	padding-right: 16px;
	font-size: 16px;
	font-family: var(--main-font);
	border-radius: 32px 0 0 32px;
}

#search button {
	background: none;
	border: none;
	border-left: solid thin black;
	padding-right: 10px;
	font-size: 16px;
	font-family: var(--main-font);
}

#large-search {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 32px;
	z-index: -1;
	height: 40px;
	border: 0.5px solid var(--alt-color);
	border-radius: 48px;
	background: white;
	padding-left: 16px;
	padding-right: 16px;
	transition: width 300ms ease-out;
}

@media screen and (min-width: 1301px) {
	#large-search.search-open {
		width: 250px;
	}	
}
